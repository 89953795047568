const ID_TOKEN_KEY = "id_token";
const TOKEN_FACILITYDETAILS = "facility_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const saveFacilityToken = token => {
  window.localStorage.setItem(TOKEN_FACILITYDETAILS, token);
};

export const getFacilityToken = () => {
  return window.localStorage.getItem(TOKEN_FACILITYDETAILS);
};

export default { getToken, saveToken, destroyToken,saveFacilityToken,getFacilityToken };
