import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [

    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue")
        }]
    },
    {
      path: "/",
      redirect: "/admindashboard",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/admindashboard",
          name: "admindashboard",
          component: () => import("@/views/pages/AdminDashboard.vue")
        },
        {
          path: "/User",
          name: "User",
          component: () => import("@/views/pages/User.vue")
        },
        {
          path: "/UploadResults",
          name: "UploadResults",
          component: () => import("@/views/pages/UploadResults.vue")
        },
      
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register")
        },
        {
          name: "confirmation",
          path: "/confirmation:eid",
          component: () => import("@/views/pages/auth/Confirmation")
        },
        {
          name: "verification",
          path: "/verification/:key",
          component: () => import("@/views/pages/auth/Verification"),
          meta: {
            guest: true
          }
        }
      ]
    },
    {
      path: "/adminlogin",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "adminlogin",
          path: "/adminlogin",
          component: () => import("@/views/pages/auth/AdminLogin")
        }       
      ]
    },
    {
      path: "/",
      redirect: "/Reports",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "/Reports",
          name: "Reports",
          component: () => import("@/views/pages/Reports.vue")
        }
      ]
    },   
    {
      path: "/ViewResults",
      name: "ViewResults",
      component: () => import("@/views/pages/ViewResults.vue")
    },
    {
      path: "/NotFound",
      name: "NotFound",
      component: () => import("@/views/pages/NotFound.vue")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    }
  ]
});
