import ApiService from "@/common/api.service";
import JwtService from "@/common/jwt.service";


// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const SETERROR = "seterror";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";


const state = {
  errors: null,
  user: {},
  userID: null,
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  userID(state) {
    return state.userID;
  }
};

const actions = {
  [LOGIN](context, credentials) {

    if (credentials.isAdmin) {
      ApiService.admininit();
      this.apimethod = "login";
    }
    else {
      ApiService.init();
      this.apimethod = "guestlogin";
    }
    return new Promise((resolve, reject) => {

      ApiService.post(this.apimethod, credentials)
        .then(({ data }) => {
          if (data.hasError) {

            context.commit(SET_ERROR, [data.message]);
            reject(data);
          }
          else {

            context.commit(SET_AUTH, data);
            resolve(data);
          }
        })
        .catch(function (error) {

          context.commit(SET_ERROR, [error]);
          reject(error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [SETERROR](context, error) {
    context.commit(SET_ERROR, [error]);
  },
  [REGISTER](context, credentials) {
    ApiService.admininit();
    return new Promise((resolve, reject) => {
      ApiService.post("AddUpdateUser", credentials)
        .then(({ data }) => {

          if (data.hasError) {
            context.commit(SET_ERROR, [data.message]);
            reject(data);
          }
          else {

            //context.commit(SET_AUTH, data);
            resolve(data);
          }

        })
        .catch(function (error) {

          context.commit(SET_ERROR, [error]);
          reject(error);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      if (JwtService.getToken() != null && JwtService.getToken() != "") {

        const Token = JwtService.getToken().split("~")[0];
        const userID = JwtService.getToken().split("~")[1];
        const firstname = JwtService.getToken().split("~")[2];
        const Admin = JwtService.getToken().split("~")[3];
        const facilityid = JwtService.getToken().split("~")[4];
        const Username = JwtService.getToken().split("~")[5];


        const data = { token: Token, id: userID, firstName: firstname, isAdmin: Admin, companyID: facilityid,userName:Username }
        context.commit(SET_AUTH, data);
      }
      else {
        context.commit(PURGE_AUTH);
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.userID = user.id;
    state.errors = {};
    console.log(user.companyID);
    if (!user.isAdmin) {
      user.isAdmin = 0;
    }

    if (!user.lastName) {
      user.lastName = "";
    }

    if (!user.userName) {
      user.userName = "";
    }
    JwtService.saveToken(state.user.token + "~" + state.user.id + "~" + state.user.firstName + " " + state.user.lastName + "~" + user.isAdmin + "~" + user.companyID + "~" + user.userName);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
